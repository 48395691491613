// Polyfills
require('./polyfills/object.entries/index.ts');
require('es6-object-assign/auto');
require('es6-promise/auto');

// Allow global access (using webpack externals)
// see vendors.json for details
(window as any).dormakaba.vendorReact = (window as any).dormakaba.vendorReact || {};

if (!(window as any).dormakaba.vendorReact.hasOwnProperty('vendor/react')) {
	Object.defineProperty((window as any).dormakaba.vendorReact, 'vendor/react', {
		value: require('react'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorReact.hasOwnProperty('vendor/react-dom')) {
	Object.defineProperty((window as any).dormakaba.vendorReact, 'vendor/react-dom', {
		value: require('react-dom'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}

if (!(window as any).dormakaba.vendorReact.hasOwnProperty('vendor/classnames')) {
	Object.defineProperty((window as any).dormakaba.vendorReact, 'vendor/classnames', {
		value: require('classnames'),
		writable: false,
		enumerable: false,
		configurable: false,
	});
}
