// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries
// IE9-11 Object.entries polyfill
//

import entries from 'object.entries';
if (!(Object as any).entries) {
	entries.shim();
}

export type Entries = <T>(object: { [key: string]: T }) => Array<[string, T]>;

const objectEntries: Entries = (Object as any).entries || entries;
export default objectEntries;
